import {
  AdjustForm,
  AdjustForwardResponse,
  AdjustItem,
  AdjustmentItem
} from "@/components/batch/adjust/models/batch-adjust.model";
import {
  CombineOperationData,
  NewBatchFormModel
} from "@/components/batch/combine/model/batch-combine.model";
import {
  ConvertionResult,
  ConvertItem,
  PayloadConvert
} from "@/components/batch/convert/model/batchConvert.model";
import { SplitForwardResponse } from "@/components/batch/split/batch-split.model";
import {
  BatchAction,
  CustomBatch,
  InventoryLocationSplit,
  PayloadMove,
  ReservationPayload
} from "@/interfaces/batch";
import flatten from "lodash/flatten";
import map from "lodash/map";
import HttpService from "./http.service";
import { messagesService } from "./messages.service";

class BatchOperationsService extends HttpService {
  public async createOperation(
    reservation: BatchAction<CustomBatch>,
    operation: "adjust" | "split" | "combine" | "convert" | "move",
    isMapReservationDone?: boolean
  ) {
    this.uri = `/inventory/operations/${operation}`;
    try {
      const res = await super.post({
        reservation: isMapReservationDone
          ? reservation
          : this.mapReservation(reservation, operation)
      });
      return res;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return null;
    }
  }

  public async getAdjustment(adjID: string) {
    try {
      this.uri = `/inventory/batch_adjustments/${adjID}`;
      const response = await super.get(null, false);
      return response.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
    }
  }

  public async forwardAjust(
    operation: string,
    adjustitem: AdjustItem,
    showLoadingWindow: () => void
  ): Promise<AdjustForwardResponse> {
    const adjusts: AdjustmentItem[] = adjustitem.form.map(
      (formItem: AdjustForm) => {
        return {
          batch_fraction_uid: formItem.batch_fraction_uid!,
          new_quantity: +formItem.new_quantity!,
          reason: formItem.adjustment_reason!,
          batch_adjustment_type_id: formItem.batch_adjustment_type_id!
        };
      }
    );
    let pinCode = await this.authorize();

    if (!pinCode) {
      return { success: false, pinCode, errors: null };
    }

    try {
      showLoadingWindow();
      this.uri = `/inventory/operations/${operation}/forward`;

      const response = await super.put(
        {},
        {
          adjusts
        },
        false,
        pinCode
      );

      return { success: !!response, pinCode, errors: null };
    } catch (errors) {
      if (errors.response.status === 403) {
        pinCode = null;
      }
      return { success: false, errors, pinCode };
    }
  }

  public async finishAdjust(operation: string) {
    this.uri = `/inventory/operations/${operation}/finish`;
    try {
      const response = await super.put({}, {}, false);
      messagesService.renderSuccessMessage("batch_move.success");
      return !!response;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return false;
    }
  }

  public async forwardSplit(
    operationUId: string,
    formData: InventoryLocationSplit[],
    showLoadingWindow: () => void
  ): Promise<SplitForwardResponse> {
    const pinCode = await this.authorize();
    if (!pinCode || pinCode === null) {
      return { success: false, pinCode, errors: null };
    }

    try {
      showLoadingWindow();
      this.uri = `/inventory/operations/${operationUId}/forward`;
      const response = await super.put(
        {},
        { splits: formData },
        false,
        pinCode
      );
      return {
        success: !!response,
        pinCode,
        errors: null,
        data: response
      };
    } catch (errors) {
      return { success: false, errors, pinCode };
    }
  }

  public async finishSplit(operationUId: string, bioTrackTraceability = false) {
    this.uri = `/inventory/operations/${operationUId}/finish`;
    try {
      const response = await super.put({}, {}, false);
      messagesService.renderSuccessMessage("batch_move.success");
      return response;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      // returning error messages itself to reprsent message data
      if (bioTrackTraceability) {
        return error.response;
      }
      return false;
    }
  }

  public async finishCombine(
    operationUId: string,
    formData: NewBatchFormModel | null = null,
    hasMetrc: boolean = false
  ) {
    this.uri = `/inventory/operations/${operationUId}/finish`;
    const operation = await this.operationCancel({ cancel: !formData });
    if (operation.isCanceled) {
      return null;
    }
    if (operation.pinCode) {
      try {
        const response = await super.put(
          {},
          {
            destination: {
              sku: formData!.product,
              inventory_locations: [
                {
                  inventory_location_id: formData!.room,
                  quantity: formData!.quantity
                }
              ],
              options: {
                metrc_item:
                  formData && formData.metrc_item
                    ? formData.metrc_item.name
                    : undefined,
                strain_id: formData!.strain,
                tracking_id: formData!.secondaryID
              }
            }
          },
          false,
          operation.pinCode
        );
        return response;
      } catch (error) {
        if (hasMetrc) {
          return { errors: messagesService.parseMetrcError(error) };
        }
        messagesService.renderErrorMessage(error);
        return null;
      }
    } else {
      return null;
    }
  }

  public async getCombine(
    operationUId: string
  ): Promise<CombineOperationData[] | null> {
    this.uri = "/inventory/batch_combinations";
    try {
      const response = await super.get(
        {
          "q[operation_reference_eq]": operationUId
        },
        false
      );

      return response.data.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return null;
    }
  }

  public async finishConvert(operation: string): Promise<boolean> {
    this.uri = `/inventory/operations/${operation}/finish`;
    try {
      const res = await super.put({}, {}, false);
      messagesService.renderSuccessMessage("batch_move.success");
      return !!res;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return false;
    }
  }

  public async forwardConvert(
    operation: string,
    payload: ConvertItem[],
    hasMetrc: boolean
  ): Promise<any> {
    const pinCode = await this.authorize();
    if (pinCode) {
      this.uri = `/inventory/operations/${operation}/forward`;
      try {
        const res = await super.put(
          {},
          {
            conversions: this.mapConvertion(payload)
          },
          false,
          pinCode
        );
        messagesService.renderSuccessMessage("batch_convert.items_created");
        return res;
      } catch (error) {
        if (hasMetrc) {
          return { errors: messagesService.parseMetrcError(error) };
        }
        messagesService.renderErrorMessage(error);
        return null;
      }
    } else {
      return null;
    }
  }

  public async resultConvert(
    operation: string
  ): Promise<ConvertionResult[] | null> {
    this.uri = "/inventory/batch_conversions";
    const query = {
      "q[operation_reference_eq]": operation
    };
    try {
      const res = await super.get(query);
      return res.data.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return null;
    }
  }

  public async fetchOperation(opreationId: string) {
    this.uri = `/inventory/operations/${opreationId}`;
    try {
      const response = await super.get({}, false);
      return response.data;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return null;
    }
  }

  public async move(
    payload: { movements: PayloadMove[] },
    opreationId: string,
    pinCode: string,
    query?: boolean,
    bioTrackTraceEnabled?: boolean
  ) {
    this.uri = `inventory/operations/${opreationId}/forward`;
    try {
      if (query) {
        this.query = { bypass_pincode: 1 };
      }
      const response = await super.put({}, payload, query, pinCode);
      messagesService.renderSuccessMessage("batch_move.room_updated");
      return response;
    } catch (error) {
      if (bioTrackTraceEnabled) {
        return error.response.data;
      }
      messagesService.renderErrorMessage(error);
      return null;
    }
  }

  public async finishMove(operationUId: string) {
    this.uri = `/inventory/operations/${operationUId}/finish`;
    try {
      const response = await super.put({}, {});
      messagesService.renderSuccessMessage("batch_move.success");
      return response;
    } catch (error) {
      messagesService.renderErrorMessage(error);
      return null;
    }
  }

  protected mapReservation(
    reservation: BatchAction<CustomBatch>,
    typeOperation: string | null = null
  ) {
    return flatten(
      map(reservation.batches, (b: CustomBatch[]) => {
        return b.reduce((acc: ReservationPayload[], batch) => {
          const reserveQty = +batch.room_quantity_editable;
          if (
            batch.selected &&
            (reserveQty > 0 || typeOperation === "adjust")
          ) {
            acc.push({
              batch_uid: batch.batch_uid!,
              inventory_location_id: batch.room_id,
              quantity: reserveQty
            });
          }
          return acc;
        }, []);
      })
    );
  }

  protected mapConvertion(payload: ConvertItem[]): PayloadConvert[] {
    return payload.reduce((acc: PayloadConvert[], b: ConvertItem) => {
      acc.push({
        options: {
          ...b.options,
          metrc_item: b.options.metrc_item ? b.options.metrc_item.name : null
        },
        origin: b
          .origin!.filter(i => +i.room_quantity)
          .map(o => ({
            batch_fraction_uid: o.batch_fraction_uid,
            quantity: +o.room_quantity
          })),
        destination: {
          sku: b.destination!.product!.sku,
          inventory_locations: [
            {
              inventory_location_id: b.destination!.inventory_locations[0]
                .inventory_location_id,
              quantity: +b.destination!.inventory_locations[0].quantity!
            }
          ]
        }
      });
      return acc;
    }, []);
  }

  private async operationCancel(data: { cancel: boolean }) {
    try {
      if (data.cancel) {
        await super.put({}, {}, false);
        messagesService.renderSuccessMessage("batch_move.success");
        return { isCanceled: true, pinCode: null };
      } else {
        const pinCode = await this.authorize();
        return { isCanceled: false, pinCode };
      }
    } catch (e) {
      return { isCanceled: true, pinCode: null };
    }
  }
}

export const batchOperationsService = new BatchOperationsService();
